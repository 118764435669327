import React, { Component } from 'react'
import Layout from '../components/layout'
import MetaTags from '../components/Metatags'
import {Link} from 'gatsby'
import Tabs from '../components/tabcontent';
import LightboxWrapperLogo from '../constants/MarketingMaterials/LightboxWrapperLogo';
import LightboxWrapperBanners from '../constants/MarketingMaterials/LightboxWrapperBanners';
import LightboxWrapperBfcm from '../constants/MarketingMaterials/LigntboxWrapperBfcm';
// Import Simple React Lightbox
import SimpleReactLightbox from "simple-react-lightbox";

import bfcmImg1 from '../images/marketing-material/logo-assets/optinly_Color_Transparent.png';
import bfcmImg2 from '../images/marketing-material/bfcm-materials/optinly_1228x574.png';
import bfcmImg3 from '../images/marketing-material/banners/01.png';

import iconImg1 from '../images/marketing-material/icons/b2b.png';
import iconImg2 from '../images/marketing-material/icons/content publishers.png';
import iconImg3 from '../images/marketing-material/icons/influencers.png';
import iconImg4 from '../images/marketing-material/icons/marketing agency.png';

class marketingMaterial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false
        }
    }
    toggleModal = () => {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
    }
    render() {
        return (
            <Layout className="landing-page-marketing-material">
                <MetaTags
                    title="Optinly Marketing Tool Kit | Free Kit"
                    description="Promote Optinly with us. Make use of Optinly's affiliate marketing kit and earn passive income while round the year. Join our partner program to earn 30% recurring commission."
                    keywords="marketing tool kit, marketing kit, affiliate marketing kit, digital marketing media kit"
                />
                {
                    this.state.modalOpen &&
                    <>
                    <div className="modal asset-modal" role="dialog" style={(this.state.modalOpen) ? {display: 'block'} : ''} data-backdrop="static">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Download Marketing Kit</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.toggleModal}>
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="card">
                                                <div className="card-header">
                                                    <img src={bfcmImg1} />
                                                </div>
                                                <div className="card-body">
                                                    <h5 className="mb-2">Logo Assets</h5>
                                                        <a href="https://raw.githubusercontent.com/optinly/optinly-images/master/marketing-materials/optinly-logo-assets.zip" className="btn btn-primary btn-action mt-0">Download</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="card">
                                                <div className="card-header">
                                                    <img src={bfcmImg2} />
                                                </div>
                                                <div className="card-body">
                                                    <h5 className="mb-2">BFCM Materials</h5>
                                                        <a href="https://raw.githubusercontent.com/optinly/optinly-images/master/marketing-materials/optinly-bfcm-materials.zip" className="btn btn-primary btn-action mt-0">Download</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="card">
                                                <div className="card-header">
                                                    <img src={bfcmImg3} alt="Optinly Lead Capturing Tool" />
                                                </div>
                                                <div className="card-body">
                                                    <h5 className="mb-2">Banners</h5>
                                                        <a href="https://raw.githubusercontent.com/optinly/optinly-images/master/marketing-materials/optinly-banner-materials.zip" className="btn btn-primary btn-action mt-0">Download</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className={`modal-backdrop ${(this.state.modalOpen) ? 'fade show' : ''}`}></div>
                    </>
                }
                <div className="hero-banner-container">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-6">
                                <h2>Optinly Affiliate <br />Marketing Materials</h2>
                                <p>Join the Optinly Affiliate / Partner Program Today! <br /> Get a 30% recurring commission for every successful referral you make (for 1 year)!</p>
                                <p>
                                    <a className="btn btn-primary me-3" href="https://retainful.tapfiliate.com/publisher/signup/optinly-partner-program/" target="_blank" rel="noopener">Affiliate SignUp</a>
                                    <button onClick={this.toggleModal} className="btn btn-primary">
                                        Download Marketing Kit
                                    </button>
                                    {/* <a className="btn btn-primary" href="/images/marketing-materials/Flycart_BFCM_2019_Images_and_Logo.zip" rel="noopener">Download Marketing Kit</a> */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-features-container text-center">
                    <div className="container">
                        <div className="row justify-content-center mb-5">
                            <div className="col-sm-8">
                                <h3 className="mb-2">Who Can Join Optinly’s Affiliate/Partner Program?</h3>
                                <p>Optinly is an ideal popup tool that serves all kinds of affiliates. Any affiliate who has a website and would want to acquire leads and scale revenue can use Optinly.</p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-sm-3">
                                <img src={iconImg2} width="100" />
                                <h4>Content Marketers</h4>
                            </div>
                            <div className="col-sm-3">
                                <img src={iconImg1} width="100" />
                                <h4>B2B Audience</h4>
                            </div>
                            <div className="col-sm-3">
                                <img src={iconImg3} width="100" />
                                <h4>Influencers</h4>
                            </div>
                            <div className="col-sm-3">
                                <img src={iconImg4} width="100" />
                                <h4>Marketing Agencies</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-features-content-section">
                    <div className="container">
                        <Tabs>
                            <div slot="tab-list-heading" id="one">
                                Logo Asset
                            </div>
                            <div slot="tab-panel-content" id="one">
                                <SimpleReactLightbox>
                                    <LightboxWrapperLogo />
                                </SimpleReactLightbox>
                            </div>
                            <div slot="tab-list-heading" id="two">
                                Banners
                            </div>
                            <div slot="tab-panel-content" id="two">
                                <SimpleReactLightbox>
                                    <LightboxWrapperBanners />
                                </SimpleReactLightbox>
                            </div>
                            <div slot="tab-list-heading" id="three">
                                BFCM Materials
                            </div>
                            <div slot="tab-panel-content" id="three">
                                <SimpleReactLightbox>
                                    <LightboxWrapperBfcm />
                                </SimpleReactLightbox>
                            </div>
                            {/* <div slot="tab-list-heading" id="four">
                                E-Books
                            </div>
                            <div slot="tab-panel-content" id="four">
                                <SimpleReactLightbox>
                                    <LightboxWrapperEbooks />
                                </SimpleReactLightbox>
                            </div> */}
                        </Tabs>
                    </div>
                </div>
                {/* <div className="faq-section">
                    <div className="container">
                        <h3 className="mb-3">FAQ</h3>
                        <Accordion allowZeroExpanded>
                            {accordionItems.map((item) => (
                                <AccordionItem key={item.uuid}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            {item.heading}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                    {item.content}
                                    </AccordionItemPanel>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </div>
                </div> */}
                <div className="bf-cta-section gray-light-bg">
                    <div className="container py-5 text-center">
                        <h3 className="mb-2">Start Growing Your Revenue With Optinly</h3>
                        <p>
                            <Link className="btn-action mt-2" to="/partner-program">Join Optinly Affiliate Program Now</Link>
                        </p>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default marketingMaterial
