import React from "react";
// Import SRLWrapper
import { SRLWrapper } from "simple-react-lightbox";

import bfcmImg1 from '../../images/marketing-material/banners/01.png';
import bfcmImg2 from '../../images/marketing-material/banners/02.png';
import bfcmImg3 from '../../images/marketing-material/banners/03.png';
import bfcmImg4 from '../../images/marketing-material/banners/04.png';
import bfcmImg5 from '../../images/marketing-material/banners/05.png';
import bfcmImg6 from '../../images/marketing-material/banners/06.png';
import bfcmImg7 from '../../images/marketing-material/banners/07.png';
 
function LightboxWrapperBanners() {
  return (
    <div className="LightboxWrapper">
          <SRLWrapper>
          <div className="row">
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg1} data-attribute="SRL">
                            <img src={bfcmImg1} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg2} data-attribute="SRL">
                            <img src={bfcmImg2} alt="Optinly Banner" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg3} data-attribute="SRL" alt="Optinly Lead Capturing Tool">
                            <img src={bfcmImg3}  alt="Optinly Lead Capturing Tool" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg4} data-attribute="SRL" alt="Optinly WordPress Popup Plugin">
                            <img src={bfcmImg4} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg5} data-attribute="SRL" alt="Optinly Popup Plugin">
                            <img src={bfcmImg5} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg6} data-attribute="SRL" alt="Optinly WooCommerce Plugin">
                            <img src={bfcmImg6} />
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg7} data-attribute="SRL" alt="Optinly WordPress Popup Plugin">
                            <img src={bfcmImg7} />
                        </a>
                    </div>
                </div>
            </div>
        </div>      
        
      </SRLWrapper>
    </div>
  );
}
 
export default LightboxWrapperBanners;