import React from "react";
// Import SRLWrapper
import { SRLWrapper } from "simple-react-lightbox";

import bfcmImg1 from '../../images/marketing-material/logo-assets/optinly_Black_BG_White.png';
import bfcmImg2 from '../../images/marketing-material/logo-assets/optinly_Black_Transparent.png';
import bfcmImg3 from '../../images/marketing-material/logo-assets/optinly_Color_BG_White.png';
import bfcmImg4 from '../../images/marketing-material/logo-assets/optinly_Color_Transparent.png';
import bfcmImg5 from '../../images/marketing-material/logo-assets/optinly_Favicon_BG_White.png';
import bfcmImg6 from '../../images/marketing-material/logo-assets/optinly_Favicon_Transparent.png';
import bfcmImg7 from '../../images/marketing-material/logo-assets/optinly_Greyscale_BG_White.png';
import bfcmImg8 from '../../images/marketing-material/logo-assets/optinly_Greyscale_Transparent.png';
import bfcmImg9 from '../../images/marketing-material/logo-assets/optinly_White_BG_Black.png';
import bfcmImg10 from '../../images/marketing-material/logo-assets/optinly_White_Transparent.png';
 
function LightboxWrapperLogo() {
  return (
    <div className="LightboxWrapper">
          <SRLWrapper>
          <div className="row">
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg1} data-attribute="SRL">
                            <img alt="Optinly Logo" src={bfcmImg1} />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_Black_BG_White</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg2} data-attribute="SRL">
                            <img alt="Optinly Logo" src={bfcmImg2} />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_Black_Transparent</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg3} data-attribute="SRL">
                            <img alt="Optinly Logo" src={bfcmImg3} />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_Color_BG_White</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg4} data-attribute="SRL">
                            <img alt="Optinly Logo" src={bfcmImg4} />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_Color_Transparent</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg5} data-attribute="SRL">
                            <img alt="Optinly Logo" src={bfcmImg5} />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_Favicon_BG_White</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg6} data-attribute="SRL">
                            <img alt="Optinly Logo" src={bfcmImg6} />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_Favicon_Transparent</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg7} data-attribute="SRL">
                            <img alt="Optinly Logo" src={bfcmImg7} />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_Greyscale_BG_White</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg8} data-attribute="SRL">
                            <img alt="Optinly Logo" src={bfcmImg8} />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_Greyscale_Transparent</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg9} data-attribute="SRL">
                            <img alt="Optinly Logo" src={bfcmImg9} />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_White_BG_Black</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg10} data-attribute="SRL">
                            <img alt="Optinly Logo" src={bfcmImg10} />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_White_Transparent</h5>
                    </div>
                </div>
            </div>
        </div>      
        
      </SRLWrapper>
    </div>
  );
}
 
export default LightboxWrapperLogo;