import React from "react";
// Import SRLWrapper
import { SRLWrapper } from "simple-react-lightbox";

import bfcmImg1 from '../../images/marketing-material/bfcm-materials/optinly_200x200.png';
import bfcmImg2 from '../../images/marketing-material/bfcm-materials/optinly_1080x1080.png';
import bfcmImg3 from '../../images/marketing-material/bfcm-materials/optinly_400x400.png';
import bfcmImg4 from '../../images/marketing-material/bfcm-materials/optinly_1228x574.png';
import bfcmImg5 from '../../images/marketing-material/bfcm-materials/optinly_960x450.png';
import bfcmImg6 from '../../images/marketing-material/bfcm-materials/optinly_970x90.png';
import bfcmImg7 from '../../images/marketing-material/bfcm-materials/optinly_1024x850.png';
import bfcmImg8 from '../../images/marketing-material/bfcm-materials/optinly_300x600.png';
import bfcmImg9 from '../../images/marketing-material/bfcm-materials/optinly_800x1200.png';
import bfcmImg10 from '../../images/marketing-material/bfcm-materials/0102.png';
import bfcmImg11 from '../../images/marketing-material/bfcm-materials/bfcm_deal_cyberchimps.png';
import bfcmImg12 from '../../images/marketing-material/bfcm-materials/bfcm_deal_optinly.png';
import bfcmImg13 from '../../images/marketing-material/bfcm-materials/black_friday_featured_image.png';
import bfcmImg14 from '../../images/marketing-material/bfcm-materials/optinly_800x450.png';
import bfcmImg15 from '../../images/marketing-material/bfcm-materials/optinly-600x380.png';
import bfcmImg16 from '../../images/marketing-material/bfcm-materials/poster_02.png';
 
function LightboxWrapperBfcm() {
  return (
    <div className="MyComponent">
          <SRLWrapper>
          <div className="row">
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg1} data-attribute="SRL">
                            <img src={bfcmImg1} alt="Optinly BFCM Banner" />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_200x200</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg2} data-attribute="SRL">
                            <img src={bfcmImg2} alt="Optinly Black Friday Sale" />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_1080x1080</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg3} data-attribute="SRL">
                            <img src={bfcmImg3} alt="Optinly Black Friday Cyber Monay Sale" />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_400x400</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg4} data-attribute="SRL">
                            <img src={bfcmImg4} alt="Optinly 2020 Black Friday" />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_1228x574</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg5} data-attribute="SRL">
                            <img src={bfcmImg5} alt="Optinly Black Friday Sale Banner" />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_960x450</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg6} data-attribute="SRL">
                            <img src={bfcmImg6} alt="Optinly BFCM Floating Banner" />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_970x90</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg7} data-attribute="SRL">
                            <img src={bfcmImg7} alt="Optinly Black Friday Sale" />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_1024x850</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg8} data-attribute="SRL">
                            <img src={bfcmImg8} alt="Optinly Black Friday Sale" />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_300x600</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg9} data-attribute="SRL">
                            <img src={bfcmImg9} alt="Optinly Black Friday Sale" />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_800x1200</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg10} data-attribute="SRL">
                            <img src={bfcmImg10} alt="Optinly Black Friday Sale" />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_1633x1633</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg11} data-attribute="SRL">
                            <img src={bfcmImg11} alt="Optinly Black Friday Sale" />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>bfcm_deal_cyberchimps</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg12} data-attribute="SRL">
                            <img src={bfcmImg12} alt="Optinly Black Friday Sale" />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>bfcm_deal_optinly</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg13} data-attribute="SRL">
                            <img src={bfcmImg13} alt="Optinly Black Friday Sale" />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>black_friday_featured_image</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg14} data-attribute="SRL">
                            <img src={bfcmImg14} alt="Optinly Black Friday Sale" />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly_800x450</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg15} data-attribute="SRL">
                            <img src={bfcmImg15} alt="Optinly Goal Based Popup Builder" />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>optinly-600x380</h5>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card">
                    <div className="card-header">
                        <a href={bfcmImg16} data-attribute="SRL">
                            <img src={bfcmImg16} alt="Optinly Black Friday Sale Banner" />
                        </a>
                    </div>
                    <div className="card-body">
                        <h5>poster_02</h5>
                    </div>
                </div>
            </div>
        </div>      
        
      </SRLWrapper>
    </div>
  );
}
 
export default LightboxWrapperBfcm;